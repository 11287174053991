<div class="col-md-12">
  <div class="row border-bottom-gray-light">
    <div class="col-md-6 pl-0">
      <h1 *ngIf="currentTitle != 'VI'" class="border-none no-print">Data Submissions</h1>
      <h1 *ngIf="currentTitle == 'VI'" class="border-none no-print">PPR Submissions</h1>
    </div>
    <!-- .end col-md-6 -->
    <div
      [ngClass]="_claimService.currentTitle === titleEnum[titleEnum.VI] ? 'col-md-4 col-md-push-2 pr-0' : 'col-md-3 col-md-push-3 pr-0'">
      <!-- .begin year picker dropdown -->
      <fiscalYearDropdown *ngIf="!_claimService.isNavApprover"></fiscalYearDropdown>
      <!-- .end year picker dropdown -->
    </div>
    <!-- .end col-md-6 -->
  </div>
  <!-- .end row -->
</div>
<!-- .end col-md-12  -->
<div class="row">
  <acl-aaastate-review-nav></acl-aaastate-review-nav>
</div>
<!-- .end col-md-12 -->
<div class="row">
  <div class="col-md-12">
    <acl-review-header [model]="buildAclHeader()"></acl-review-header>
  </div>
</div>
<!-- .end row -->
<div class="col-md-3 no-print" *ngIf="showNavigation">
  <div class="p-1 row">
    <nav class="side-navigation">
      <ul class="list-unstyled">
        <li class="title">
          <p
            *ngIf="((_claimService.isNavStateSubmission) && (currentTitle == 'III') && (_claimService.isAAAEnabledState))">
            {{navHeader}}
          </p>
          <p *ngIf=" (currentTitle == 'VI')">
            Program Performance Report (PPR)
          </p>
          <nav>
            <!--BEGIN TITLE 6 NAV-->
            <ul *ngIf="currentTitle == 'VI'" class="">
              <li>
                <a id="titleVIPPROverviewLnk" [routerLink]="['/titlevi/titleVIPPROverview']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>Overview</strong>
                  </p>
                  <span class="sr-only">Current:</span>
                  <span *ngIf="router.url.includes('/titlevi/titleVIPPROverview')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
            </ul>
            <h2 *ngIf="currentTitle == 'VI'">Part A/B</h2>
            <ul *ngIf="currentTitle == 'VI'" class="">
              <li>
                <a id="staffInfoABLnk" [routerLink]="['/titlevi/staffInfoAB']" [routerLinkActive]="['active']"
                  aria-label="Staffing Information A/B">
                  <p>
                    <strong><i
                        *ngIf="staffInfoABStatus == statusEnum.L1InProgressError || staffInfoABStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('titlevi/staffInfoAB'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i>
                      <i *ngIf="staffInfoABStatus == statusEnum.L2ValidatedWarning"
                        [ngClass]="(router.url.includes('titlevi/staffInfoAB'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                        aria-hidden="true">
                        <span class="sr-only">Warning:</span></i>
                      Staffing Information</strong>
                  </p>
                  <span *ngIf="router.url.includes('/titlevi/staffInfoAB')">
                    <span class="sr-only">Current:</span>
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li>
                <a id="nutritionServicesLnk" [routerLink]="['/titlevi/nutritionServices']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong><i
                        *ngIf="nutritionServicesStatus == statusEnum.L1InProgressError || nutritionServicesStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('titlevi/nutritionServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i>
                      <i *ngIf="nutritionServicesStatus == statusEnum.L2ValidatedWarning"
                        [ngClass]="(router.url.includes('titlevi/nutritionServices'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                        aria-hidden="true">
                        <span class="sr-only">Warning:</span></i>
                      Nutrition Services</strong>
                  </p>
                  <span *ngIf="router.url.includes('/titlevi/nutritionServices')">
                    <span class="sr-only">Current:</span>
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <!--Begin Supportive Services logic for sub tabs-->
              <li
                *ngIf="!router.url.includes('/titlevi/accessServices') && !router.url.includes('/titlevi/inHome') && !router.url.includes('/titlevi/otherServices')">
                <a id="accessServicesLnk" [routerLink]="['/titlevi/accessServices']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="tviOverviewState?.isInError('SupportiveServicesOverview')"
                        [ngClass]="(router.url.includes('titlevi/accessServices') || router.url.includes('titlevi/inHomeServices') || router.url.includes('titlevi/otherServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i>
                      <i *ngIf="tviOverviewState?.isInWarning('SupportiveServicesOverview')"
                        [ngClass]="(router.url.includes('titlevi/accessServices') || router.url.includes('titlevi/inHomeServices') || router.url.includes('titlevi/otherServices'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                        aria-hidden="true">
                        <span class="sr-only">Warning:</span></i> Supportive Services
                    </strong>
                  </p>
                </a>
              </li>
              <li
                *ngIf="router.url.includes('/titlevi/accessServices') || router.url.includes('/titlevi/inHome') || router.url.includes('/titlevi/otherServices')"
                class="leadNav subActive">
                <strong>
                  <i *ngIf="tviOverviewState?.isInError('SupportiveServicesOverview')"
                    [ngClass]="(router.url.includes('titlevi/accessServices') || router.url.includes('titlevi/inHomeServices') || router.url.includes('titlevi/otherServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i>
                  <i *ngIf="tviOverviewState?.isInWarning('SupportiveServicesOverview')"
                    [ngClass]="(router.url.includes('titlevi/accessServices') || router.url.includes('titlevi/inHomeServices') || router.url.includes('titlevi/otherServices'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                    aria-hidden="true">
                    <span class="sr-only">Warning:</span></i> Supportive Services
                </strong>
                <ul>
                  <li>
                    <a id="accessServicesLnk" [routerLink]="['/titlevi/accessServices']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="accessServicesStatus == statusEnum.L1InProgressError || accessServicesStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('titlevi/accessServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i><i
                          *ngIf="accessServicesStatus == statusEnum.L2ValidatedWarning"
                          [ngClass]="(router.url.includes('titlevi/accessServices'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                          aria-hidden="true">
                          <span class="sr-only">Warning:</span></i>
                        Access Services
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="inHomeServicesLnk" [routerLink]="['/titlevi/inHomeServices']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="inHomeServicesStatus == statusEnum.L1InProgressError || inHomeServicesStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('titlevi/inHomeServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i><i
                          *ngIf="inHomeServicesStatus == statusEnum.L2ValidatedWarning"
                          [ngClass]="(router.url.includes('titlevi/inHomeServices'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                          aria-hidden="true">
                          <span class="sr-only">Warning:</span></i>
                        In-Home Services
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="otherServicesLnk" [routerLink]="['/titlevi/otherServices']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="otherServicesStatus == statusEnum.L1InProgressError || otherServicesStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('titlevi/otherServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i><i
                          *ngIf="otherServicesStatus == statusEnum.L2ValidatedWarning"
                          [ngClass]="(router.url.includes('titlevi/otherServices'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                          aria-hidden="true">
                          <span class="sr-only">Warning:</span></i>
                        Other Supportive Services
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <!--End Supportive Services Logic-->
              <li>
                <a id="financeABLnk" [routerLink]="['/titlevi/financeAB']" [routerLinkActive]="['active']">
                  <p>
                    <strong><i
                        *ngIf="financeABStatus == statusEnum.L1InProgressError || financeABStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('titlevi/financeAB'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Finance</strong>
                  </p>
                  <span *ngIf="router.url.includes('/titlevi/financeAB')">
                    <span class="sr-only">Current:</span>
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li>
                <a id="storytellingLnk" [routerLink]="['/titlevi/storytelling']" [routerLinkActive]="['active']">
                  <p>
                    <strong><i
                        *ngIf="storytellingStatus == statusEnum.L1InProgressError || storytellingStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('titlevi/storytelling'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Storytelling</strong>
                  </p>
                  <span *ngIf="router.url.includes('/titlevi/storytelling')">
                    <span class="sr-only">Current:</span>
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
            </ul>
            <h2 *ngIf="titleViIsPartCRequired()">Part C</h2>
            <ul *ngIf="currentTitle == 'VI'" class="">

              <!-- Todo: Refactor this and move all title VI to a sub component. Tried adding div so that only once we could call titleViIsPartCRequired and that broke styling.-->

              <li *ngIf="titleViIsPartCRequired()">
                <a id="staffInfoCLnk" [routerLink]="['/titlevi/staffInfoC']" [routerLinkActive]="['active']"
                  aria-label="Staffing Information C">
                  <p>
                    <strong><i
                        *ngIf="staffInfoCStatus == statusEnum.L1InProgressError || staffInfoCStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('titlevi/staffInfoC'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Staffing Information</strong>
                  </p>
                  <span *ngIf="router.url.includes('/titlevi/staffInfoC')">
                    <span class="sr-only">Current:</span>
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li *ngIf="titleViIsPartCRequired()">
                <a id="caregiverCharsLnk" [routerLink]="['/titlevi/caregiverChars']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong><i
                        *ngIf="caregiverCharsStatus == statusEnum.L1InProgressError || caregiverCharsStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('caregiverChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Total Caregivers Served</strong>
                  </p>
                  <span *ngIf="router.url.includes('/titlevi/caregiverChars')">
                    <span class="sr-only">Current:</span>
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <!--Begin Caregiver Services logic for sub tabs-->
              <li
                *ngIf="titleViIsPartCRequired() && !router.url.includes('/titlevi/caregiverServices') && !router.url.includes('/titlevi/suppServices') && !router.url.includes('/titlevi/respite')">
                <a id="caregiverServicesLnk" [routerLink]="['/titlevi/caregiverServices']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="tviOverviewState?.isInError('CaregiverSupportServicesOverview')"
                        [ngClass]="(router.url.includes('titlevi/caregiverServices') || router.url.includes('titlevi/suppServices') || router.url.includes('titlevi/respite'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i>
                      <i *ngIf="tviOverviewState?.isInWarning('CaregiverSupportServicesOverview')"
                        [ngClass]="(router.url.includes('titlevi/caregiverServices') || router.url.includes('titlevi/suppServices') || router.url.includes('titlevi/respite'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                        aria-hidden="true">
                        <span class="sr-only">Warning:</span></i> Caregiver Support Services
                    </strong>
                  </p>
                </a>
              </li>
              <li
                *ngIf="titleViIsPartCRequired() && router.url.includes('/titlevi/caregiverServices') || router.url.includes('/titlevi/suppServices') || router.url.includes('/titlevi/respite')"
                class="leadNav subActive">
                <strong>
                  <i *ngIf="tviOverviewState?.isInError('CaregiverSupportServicesOverview')"
                    [ngClass]="(router.url.includes('titlevi/caregiverServices') || router.url.includes('titlevi/suppServices') || router.url.includes('titlevi/respite'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i>
                  <i *ngIf="tviOverviewState?.isInWarning('CaregiverSupportServicesOverview')"
                    [ngClass]="(router.url.includes('titlevi/caregiverServices') || router.url.includes('titlevi/suppServices') || router.url.includes('titlevi/respite'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                    aria-hidden="true">
                    <span class="sr-only">Warning:</span></i> Caregiver Support Services
                </strong>
                <ul>
                  <li>
                    <a id="caregiverServicesLnk" [routerLink]="['/titlevi/caregiverServices']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="caregiverServicesStatus == statusEnum.L1InProgressError || caregiverServicesStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('titlevi/caregiverServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i>
                        <i *ngIf="caregiverServicesStatus == statusEnum.L2ValidatedWarning"
                          [ngClass]="(router.url.includes('titlevi/caregiverServices'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                          aria-hidden="true">
                          <span class="sr-only">Warning:</span></i> Services for Caregivers
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="suppServicesLnk" [routerLink]="['/titlevi/suppServices']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="suppServicesStatus == statusEnum.L1InProgressError || suppServicesStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('titlevi/suppServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i>
                        <i *ngIf="suppServicesStatus == statusEnum.L2ValidatedWarning"
                          [ngClass]="(router.url.includes('titlevi/suppServices'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                          aria-hidden="true">
                          <span class="sr-only">Warning:</span></i> Supplemental Services
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="respiteLnk" [routerLink]="['/titlevi/respite']" [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="respiteStatus == statusEnum.L1InProgressError || respiteStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('titlevi/respite'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i>
                        <i *ngIf="respiteStatus == statusEnum.L2ValidatedWarning"
                          [ngClass]="(router.url.includes('titlevi/respite'))?'fa fa-exclamation-triangle':'fa fa-exclamation-triangle text-warning'"
                          aria-hidden="true">
                          <span class="sr-only">Warning:</span></i> Respite Care for Caregivers
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <!--End Supportive Services Logic-->
              <li *ngIf="titleViIsPartCRequired()" style="display: none;">
                <a id="financeCLnk" [routerLink]="['/titlevi/financeC']" [routerLinkActive]="['active']">
                  <p>
                    <strong><i
                        *ngIf="financeCStatus == statusEnum.L1InProgressError || financeCStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('titlevi/financeC'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Finance</strong>
                  </p>
                  <span *ngIf="router.url.includes('/titlevi/financeC')">
                    <span class="sr-only">Current:</span>
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
            </ul>
            <!--BEGIN TITLE 7 NAV-->
            <ul *ngIf="currentTitle == 'VII'" class="">
              <li>
                <a id="titleVIIOverviewLnk" [routerLink]="['/state-submissions/titleVIIOverview']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>Overview</strong>
                  </p>
                  <span *ngIf="router.url.includes('/state-submissions/titleVIIOverview')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li *ngIf="_claimService.isNavStateSubmission">
                <a id="titleVIIManageUploadsLnk" [routerLink]="['/state-submissions/titleVIIManageUploads']"
                  [ngClass]="getActiveClass('^\/state-submissions\/titleVIIManageUploads|upload[Ff]ile|titleVIIUploadConfirmation|errors$')">
                  <p>
                    <strong>Manage Uploads</strong>
                  </p>
                  <span
                    *ngIf="router.url.includes('/state-submissions/titleVIIManageUploads') || router.url.includes('/state-submissions/uploadfile') || router.url.includes('/state-submissions/titleVIIUploadConfirmation')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li *ngIf="_claimService.isNavStateSubmission">
                <a id="titleVIIManageAttachmentsLnk" [routerLink]="[]" (click)="attachDocument()"
                  [ngClass]="getActiveClass('^\/state-submissions\/attachFile|manageAttachments')">
                  <p>
                    <strong>Manage Attachments</strong>
                  </p>
                  <span
                    *ngIf="router.url.includes('/state-submissions/manageAttachments') || router.url.includes('/state-submissions/attachFile')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li>
                <a id="titleVIICasesAndComplaintsLnk"
                  [routerLink]="['/state-submissions/titleVIICasesAndComplaints']" [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tviiStatuses.caseAndComplaintSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.caseAndComplaintSectionStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('/state-submissions/titleVIICasesAndComplaints'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Cases and Complaints
                    </strong>
                  </p>
                  <span *ngIf="router.url.includes('/state-submissions/titleVIICasesAndComplaints')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <!--If the current page is not one of the sub tabs, then show just the parent label, else show the leadNav style along with properly selected sub tab-->
              <!--Begin Complaint Example logic for sub tabs-->
              <li>
                <a id="titleVIIComplaintNursingLnk"
                  *ngIf="!router.url.includes('/state-submissions/titleVIIComplaintNursing') && !router.url.includes('/state-submissions/titleVIIComplaintRcc') && !router.url.includes('/state-submissions/titleVIIComplaintOptional')"
                  [routerLink]="['/state-submissions/titleVIIComplaintNursing']" [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintNursing'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Complaint Examples
                    </strong>
                  </p>
                </a>
              </li>
              <li *ngIf="router.url.includes('/state-submissions/titleVIIComplaintNursing')" class="leadNav subActive">
                <strong>
                  <i *ngIf="_claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L2InProgressError"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintNursing'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i> Complaint Examples
                </strong>
                <ul>
                  <li>
                    <a id="titleVIIComplaintNursingLnk"
                      [routerLink]="['/state-submissions/titleVIIComplaintNursing']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintNursing'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Nursing Facility
                      </p>
                      <span>
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIIComplaintRccLnk" [routerLink]="['/state-submissions/titleVIIComplaintRcc']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.rccSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintRcc'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Residential Care Community
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIIComplaintOptionalLnk"
                      [routerLink]="['/state-submissions/titleVIIComplaintOptional']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintOptional'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Optional Example
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <li *ngIf="router.url.includes('/state-submissions/titleVIIComplaintRcc')" class="leadNav subActive">
                <strong>
                  <i *ngIf="_claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L2InProgressError"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintRcc'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i> Complaint Examples
                </strong>
                <ul>
                  <li>
                    <a id="titleVIIComplaintNursingLnk"
                      [routerLink]="['/state-submissions/titleVIIComplaintNursing']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintNursing'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Nursing Facility
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIIComplaintRccLnk" [routerLink]="['/state-submissions/titleVIIComplaintRcc']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.rccSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintRcc'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Residential Care Community
                      </p>
                      <span>
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIIComplaintOptionalLnk"
                      [routerLink]="['/state-submissions/titleVIIComplaintOptional']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintOptional'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Optional Example
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <li *ngIf="router.url.includes('/state-submissions/titleVIIComplaintOptional')" class="leadNav subActive">
                <strong>
                  <i *ngIf="_claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L2InProgressError"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintOptional'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i> Complaint Examples
                </strong>
                <ul>
                  <li>
                    <a id="titleVIIComplaintNursingLnk"
                      [routerLink]="['/state-submissions/titleVIIComplaintNursing']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.nursingfacilitySectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintNursing'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Nursing Facility
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIIComplaintRccLnk" [routerLink]="['/state-submissions/titleVIIComplaintRcc']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.rccSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.rccSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintRcc'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Residential Care Community
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIIComplaintOptionalLnk"
                      [routerLink]="['/state-submissions/titleVIIComplaintOptional']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.optionalExampleSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIComplaintOptional'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Optional Example
                      </p>
                      <span>
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
              <!--End Complaint example-->
              <!--Begin System Issue logic for sub tabs-->
              <li
                *ngIf="!router.url.includes('/state-submissions/titleVIISystemIssue1') && !router.url.includes('/state-submissions/titleVIISystemIssue2') && !router.url.includes('/state-submissions/titleVIISystemIssue3')">
                <a id="titleVIISystemIssue1Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue1']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue1'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Systems Issues
                    </strong>
                  </p>
                </a>
              </li>
              <li *ngIf="router.url.includes('/state-submissions/titleVIISystemIssue1')" class="leadNav subActive">
                <strong>
                  <i *ngIf="_claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L2InProgressError"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue1'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i> Systems Issues
                </strong>
                <ul>
                  <li>
                    <a id="titleVIISystemIssue1Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue1']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue1'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Systems Issue 1
                      </p>
                      <span>
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIISystemIssue2Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue2']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue2'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Systems Issue 2
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIISystemIssue3Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue3']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue3'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Optional Systems Issue
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <li *ngIf="router.url.includes('/state-submissions/titleVIISystemIssue2')" class="leadNav subActive">
                <strong>
                  <i *ngIf="_claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L2InProgressError"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue2'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i> Systems Issues
                </strong>
                <ul>
                  <li>
                    <a id="titleVIISystemIssue1Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue1']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue1'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Systems Issue 1
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIISystemIssue2Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue2']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue2'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Systems Issue 2
                      </p>
                      <span>
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIISystemIssue3Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue3']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue3'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Optional Systems Issue
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <li *ngIf="router.url.includes('/state-submissions/titleVIISystemIssue3')" class="leadNav subActive">
                <strong>
                  <i *ngIf="_claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L2InProgressError"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue3'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i> Systems Issues
                </strong>
                <ul>
                  <li>
                    <a id="titleVIISystemIssue1Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue1']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.systemsIssue1SectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue1'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Systems Issue 1
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIISystemIssue2Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue2']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.systemsIssue2SectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue2'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Systems Issue 2
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIISystemIssue3Lnk" [routerLink]="['/state-submissions/titleVIISystemIssue3']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.optionalSystemIssueSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIISystemIssue3'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Optional Systems Issue
                      </p>
                      <span>
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
              <!--End System Issue-->
              <li>
                <a id="titleVIIOrgStructureLnk" [routerLink]="['/state-submissions/titleVIIOrgStructure']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tviiStatuses.orgStructureSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.orgStructureSectionStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('/state-submissions/titleVIIOrgStructure'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Organizational Structure
                    </strong>
                  </p>
                  <span *ngIf="router.url.includes('/state-submissions/titleVIIOrgStructure')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li>
                <a id="titleVIIStaffAndVolunteersLnk"
                  [routerLink]="['/state-submissions/titleVIIStaffAndVolunteers']" [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tviiStatuses.staffAndVolunteerSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.staffAndVolunteerSectionStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.endsWith('/state-submissions/titleVIIStaffAndVolunteers'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Staff and Volunteers
                    </strong>
                  </p>
                  <span *ngIf="router.url.endsWith('/state-submissions/titleVIIStaffAndVolunteers')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li>
                <a id="titleVIIConflictOfInterestLnk"
                  [routerLink]="['/state-submissions/titleVIIConflictOfInterest']" [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tviiStatuses.conflictOfInterestSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.conflictOfInterestSectionStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('/state-submissions/titleVIIConflictOfInterest'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Organizational Conflicts of Interest
                    </strong>
                  </p>
                  <span *ngIf="router.url.includes('/state-submissions/titleVIIConflictOfInterest')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li>
                <a id="titleVIIFundsExpendedLnk" [routerLink]="['/state-submissions/titleVIIFundsExpended']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tviiStatuses.fundsExpendedSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.fundsExpendedSectionStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.endsWith('/state-submissions/titleVIIFundsExpended'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Funds Expended
                    </strong>
                  </p>
                  <span *ngIf="router.url.endsWith('/state-submissions/titleVIIFundsExpended')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <!--Begin Facilty logic for sub tabs-->
              <li>
                <a id="titleVIINumberAndCapacityLnk"
                  *ngIf="!router.url.includes('/state-submissions/titleVIINumberAndCapacity') && !router.url.includes('/state-submissions/titleVIIRCCInformation')"
                  [routerLink]="['/state-submissions/titleVIINumberAndCapacity']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('/state-submissions/titleVIINumberAndCapacity'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Facility
                    </strong>
                  </p>
                </a>
              </li>
              <li *ngIf="router.url.includes('/state-submissions/titleVIINumberAndCapacity')" class="leadNav subActive">
                <strong>
                  <i *ngIf="_claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L2InProgressError"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIINumberAndCapacity'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i> Facility
                </strong>
                <ul>
                  <li>
                    <a id="titleVIINumberAndCapacityLnk"
                      [routerLink]="['/state-submissions/titleVIINumberAndCapacity']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIINumberAndCapacity'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Numbers and Capacity
                      </p>
                      <span>
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIIRCCInformationLnk" [routerLink]="['/state-submissions/titleVIIRCCInformation']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIRCCInformation'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> RCC Information
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <li *ngIf="router.url.includes('/state-submissions/titleVIIRCCInformation')" class="leadNav subActive">
                <strong>
                  <i *ngIf="_claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L2InProgressError
                                           || _claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L1InProgressError
                                           || _claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L2InProgressError"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIIRCCInformation'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                    aria-hidden="true">
                    <span class="sr-only">Error:</span></i> Facility
                </strong>
                <ul>
                  <li>
                    <a id="titleVIINumberAndCapacityLnk"
                      [routerLink]="['/state-submissions/titleVIINumberAndCapacity']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.numbersAndCapacitySectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIINumberAndCapacity'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> Numbers and Capacity
                      </p>
                    </a>
                  </li>
                  <li>
                    <a id="titleVIIRCCInformationLnk" [routerLink]="['/state-submissions/titleVIIRCCInformation']"
                      [routerLinkActive]="['active']">
                      <p>
                        <i *ngIf="_claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.rccInformationSectionStatus == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/state-submissions/titleVIIRCCInformation'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span></i> RCC Information
                      </p>
                      <span>
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
              <!--End Facilty-->
              <li>
                <a id="titleVIIProgramActivitiesLnk" [routerLink]="['/state-submissions/titleVIIProgramActivities']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tviiStatuses.programActivitiesSectionStatus == statusEnum.L1InProgressError || _claimService.tviiStatuses.programActivitiesSectionStatus == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.endsWith('/state-submissions/titleVIIProgramActivities'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span></i> Program Activities
                    </strong>
                  </p>
                  <span *ngIf="router.url.endsWith('/state-submissions/titleVIIProgramActivities')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
            </ul>
            <!--END TITLE 7 NAV-->
            <!--BEGIN TITLE 3 NAV-->
            <ul *ngIf="currentTitle == 'III'" class="">
              <li>
                <a id="titleIIIOverviewLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIOverview']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>Overview</strong>
                  </p>
                  <span *ngIf="router.url.includes('/data-submissions/titleIIIOverview')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li *ngIf="(_claimService.isNavStateSubmission || _claimService.isAAAUser)">
                <a id="titleIIIManageUploadsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIManageUploads']"
                  [ngClass]="getActiveClass('^\/data-submissions\/titleIIIManageUploads|upload[Ff]ile|titleIIIUploadConfirmation|errors$')">
                  <p>
                    <strong>Manage Uploads</strong>
                  </p>
                  <span
                    *ngIf="router.url.includes('/data-submissions/titleIIIManageUploads') || router.url.includes('/data-submissions/uploadfile') || router.url.includes('/data-submissions/titleIIIUploadConfirmation')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li *ngIf="_claimService.isNavStateSubmission && !_claimService._currentSubOrg">
                <a id="titleIIIManageAttachmentsLnk" queryParamsHandling="preserve" [routerLink]="[]" (click)="attachDocument()"
                  [ngClass]="getActiveClass('^\/data-submissions\/manageAttachments|attachFile')">
                  <p>
                    <strong>Manage Attachments</strong>
                  </p>
                  <span
                    *ngIf="router.url.includes('/data-submissions/manageAttachments') || router.url.includes('/data-submissions/attachFile')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <!-- SPR nav -->
              <ng-container *ngIf="iiiViewConstraints.isSprViewable">
                <!--Aging Network-->
                <li id="tiii-aging-network-profile-top" (click)="displayAgingNetwork()"
                  (keypress)="displayAgingNetwork()" role="button" tabindex="0"
                  [ngClass]="{leadNav: !isANHomeActive, subActive: showAgingNetwork}">
                  <!--queryParamsHandling="preserve" [routerLink]="['/landing/titleIIIANHome']"-->
                  <p>
                    <strong *ngIf="!isANHomeActive">
                      <i *ngIf="_claimService.tiiiStatuses.anSection == statusEnum.L1InProgressError || _claimService.tiiiStatuses.anSection == statusEnum.L2InProgressError"
                        [ngClass]="showAgingNetwork ?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span>
                      </i>
                      Aging Network Profile
                    </strong>
                  </p>
                  <a id="tiii-aging-network-profile-active" class="active" *ngIf="isANHomeActive">
                    <p>
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.anSection == statusEnum.L1InProgressError || _claimService.tiiiStatuses.anSection == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Aging Network Profile
                      </strong>
                    </p>
                    <span>
                      <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                    </span>
                  </a>
                  <ul [hidden]="!showAgingNetwork">
                    <!--show/hide onClick of li-->
                    <li>
                      <a id="titleIIIANNetworkFundingLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIANNetworkFunding']"
                        [routerLinkActive]="['active']">
                        <p id="tiii-network-funding">
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIANNetworkFunding == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIANNetworkFunding == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIANNetworkFunding'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Network Funding
                        </p>
                        <span *ngIf="router.url.includes('titleIIIANNetworkFunding')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a id="tiii-staff-and-volunteers"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIANStaffAndVolunteers']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIANStaffAndVolunteers == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIANStaffAndVolunteers == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIANStaffAndVolunteers'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Staff And Volunteers
                        </p>
                        <span *ngIf="router.url.includes('titleIIIANStaffAndVolunteers')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a id="titleIIIANServiceProvidersLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIANServiceProviders']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIANServiceProviders == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIANServiceProviders == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIANServiceProviders'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Service Providers
                        </p>
                        <span *ngIf="router.url.includes('titleIIIANServiceProviders')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a id="titleIIIANSeniorCentersLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIANSeniorCenters']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIANSeniorCenters == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIANSeniorCenters == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIANSeniorCenters'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Senior Centers and Focal Points
                        </p>
                        <span *ngIf="router.url.includes('titleIIIANSeniorCenters')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a id="titleIIIANSelfDirectionLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIANSelfDirection']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIANSelfDirection == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIANSelfDirection == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIANSelfDirection'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Self-Direction
                        </p>
                        <span *ngIf="router.url.includes('titleIIIANSelfDirection')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a id="titleIIIANRespiteLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIANRespite']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIANRespite == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIANRespite == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIANRespite'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Respite Voucher
                        </p>
                        <span *ngIf="router.url.includes('titleIIIANRespite')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <!--Older Adults-->
                <li id="tiii-older-adults-profile-top" (click)="displayOlderAdults()" (keypress)="displayOlderAdults()"
                  role="button" tabindex="0" [ngClass]="{subActive: showOlderAdults, leadNav: !isOAHomeActive}">
                  <strong *ngIf="!isOAHomeActive">
                    <i *ngIf="_claimService.tiiiStatuses.OAParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAParent == statusEnum.L2InProgressError"
                      [ngClass]="showOlderAdults ?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                      aria-hidden="true">
                      <span class="sr-only">Error:</span>
                    </i> Older Adults
                  </strong>
                  <!-- todo update active condition -->
                  <a id="activeOAHomeLnk" class="active" *ngIf="isOAHomeActive">
                    <p>
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OAParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Older Adults
                      </strong>
                    </p>
                    <span>
                      <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                    </span>
                  </a>
                  <!-- .end todo -->
                  <ul [hidden]="!showOlderAdults">
                    <!--show/hide onClick of li-->
                    <!-- Consumer Summary-->
                    <li
                      *ngIf="(!router.url.includes('/data-submissions/OAConsumerTps')) && (!router.url.includes('/data-submissions/OAConsumerBoth')) && (!router.url.includes('/data-submissions/OAConsumerOne'))">
                      <a id="OAConsumerTpsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/OAConsumerTps']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OAConsumerParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAConsumerParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Consumer Summary
                        </strong>
                      </a>
                    </li>
                    <li
                      *ngIf="(router.url.includes('/data-submissions/OAConsumerTps')) || (router.url.includes('/data-submissions/OAConsumerBoth')) || (router.url.includes('/data-submissions/OAConsumerOne'))"
                      class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OAConsumerParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAConsumerParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Consumer Summary
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="OAConsumerTpsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/OAConsumerTps']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.OAConsumerTps == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAConsumerTps == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/OAConsumerTps'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total Persons Served
                                </p>
                                <span *ngIf="router.url.includes('OAConsumerTps')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="OAConsumerBothLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/OAConsumerBoth']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.OAConsumerBoth == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAConsumerBoth == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/OAConsumerBoth'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Persons Receiving Registered Services (Clusters 1 &amp; 2)
                                </p>
                                <span *ngIf="router.url.includes('OAConsumerBoth')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="OAConsumerOneLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/OAConsumerOne']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.OAConsumerOne == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAConsumerOne == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/OAConsumerOne'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Persons Receiving Registered Services (Cluster 1)
                                </p>
                                <span *ngIf="router.url.includes('OAConsumerOne')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Consumer Summary-->
                    <!--Personal Care-->
                    <li *ngIf="!router.url.includes('titleIIIPersonalCare')">
                      <a id="titleIIIPersonalCareCharsLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIPersonalCareChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OAPersonalCareParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAPersonalCareParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Personal Care
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIPersonalCare')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OAPersonalCareParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAPersonalCareParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Personal Care
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIPersonalCareCharsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIPersonalCareChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIPersonalCareChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIPersonalCareChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIPersonalCareChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIIPersonalCareChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIPersonalCareUnitsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIPersonalCareUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIPersonalCareUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIPersonalCareUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIPersonalCareUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIPersonalCareUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Personal Care-->
                    <!--Homemaker-->
                    <li *ngIf="!router.url.includes('titleIIIHomemaker')">
                      <a id="titleIIIHomemakerCharsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIHomemakerChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OAHomemakerParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAHomemakerParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Homemaker
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIHomemaker')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OAHomemakerParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAHomemakerParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Homemaker
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIHomemakerCharsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIHomemakerChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIHomemakerChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIHomemakerChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIHomemakerChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIIHomemakerChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIHomemakerUnitsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIHomemakerUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIHomemakerUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIHomemakerUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIHomemakerUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIHomemakerUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Homemaker-->
                    <!--Chore-->
                    <li *ngIf="!router.url.includes('titleIIIChore')">
                      <a id="titleIIIChoreCharsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIChoreChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OAChoreParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAChoreParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Chore
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIChore')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OAChoreParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAChoreParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Chore
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIChoreCharsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIChoreChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIChoreChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIChoreChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIChoreChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIIChoreChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIChoreUnitsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIChoreUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIChoreUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIChoreUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIChoreUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIChoreUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Chore-->
                    <!--Home Delivered Nutrition -->
                    <li *ngIf="!router.url.includes('titleIIIHomeDeliveredMeals')">
                      <a id="titleIIIHomeDeliveredMealsCharsLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIHomeDeliveredMealsChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OAHomeDeliveredMealsParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAHomeDeliveredMealsParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Home Delivered Nutrition
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIHomeDeliveredMeals')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OAHomeDeliveredMealsParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAHomeDeliveredMealsParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Home Delivered Nutrition
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIHomeDeliveredMealsCharsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIHomeDeliveredMealsChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIHomeDeliveredMealsChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIHomeDeliveredMealsChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIHomeDeliveredMealsChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIIHomeDeliveredMealsChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIHomeDeliveredMealsUnitsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIHomeDeliveredMealsUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIHomeDeliveredMealsUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIHomeDeliveredMealsUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIHomeDeliveredMealsUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIHomeDeliveredMealsUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Home Delivered Nutrition-->
                    <!--Adult Day Care-->
                    <li *ngIf="!router.url.includes('titleIIIAdultDayCare')">
                      <a id="titleIIIAdultDayCareCharsLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIAdultDayCareChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OAAdultDayCareParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAAdultDayCareParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Adult Day Care/Health
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIAdultDayCare')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OAAdultDayCareParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAAdultDayCareParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Adult Day Care/Health
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIAdultDayCareCharsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIAdultDayCareChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIAdultDayCareChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIAdultDayCareChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIAdultDayCareChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIIAdultDayCareChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIAdultDayCareUnitsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIAdultDayCareUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIAdultDayCareUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIAdultDayCareUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIAdultDayCareUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIAdultDayCareUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Adult Day Care-->
                    <!--Case Mgmt-->
                    <li *ngIf="!router.url.includes('titleIIICaseMgmt')">
                      <a id="titleIIICaseMgmtCharsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICaseMgmtChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OACaseMgmtCharsParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OACaseMgmtCharsParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Case Management
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIICaseMgmt')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OACaseMgmtCharsParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OACaseMgmtCharsParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Case Management
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIICaseMgmtCharsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICaseMgmtChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICaseMgmtChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICaseMgmtChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICaseMgmtChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIICaseMgmtChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIICaseMgmtUnitsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICaseMgmtUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICaseMgmtUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICaseMgmtUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICaseMgmtUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIICaseMgmtUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Case Mgmt-->
                    <!--Assisted Transportation-->
                    <li *ngIf="!router.url.includes('titleIIIAssistedTransportation')">
                      <a id="titleIIIAssistedTransportationCharsLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIAssistedTransportationChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OAAssistedTransportationParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAAssistedTransportationParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Assisted Transportation
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIAssistedTransportation')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OAAssistedTransportationParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAAssistedTransportationParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle '" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Assisted Transportation
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIAssistedTransportationCharsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIAssistedTransportationChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIAssistedTransportationChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIAssistedTransportationChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIAssistedTransportationChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIIAssistedTransportationChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIAssistedTransportationUnitsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIAssistedTransportationUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIAssistedTransportationUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIAssistedTransportationUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIAssistedTransportationUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIAssistedTransportationUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Assisted Transportation-->
                    <!--Congregate Nutrition-->
                    <li *ngIf="!router.url.includes('titleIIICongregateMeals')">
                      <a id="titleIIICongregateMealsCharsLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICongregateMealsChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OACongregateMealsParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OACongregateMealsParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Congregate Nutrition
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIICongregateMeals')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OACongregateMealsParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OACongregateMealsParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Congregate Nutrition
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIICongregateMealsCharsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICongregateMealsChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICongregateMealsChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICongregateMealsChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICongregateMealsChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIICongregateMealsChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIICongregateMealsUnitsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICongregateMealsUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICongregateMealsUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICongregateMealsUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICongregateMealsUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIICongregateMealsUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Congregate Nutrition-->
                    <!--Nutrition Counseling-->
                    <li *ngIf="!router.url.includes('titleIIINutritionCounseling')">
                      <a id="titleIIINutritionCounselingCharsLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIINutritionCounselingChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OANutritionCounselingParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OANutritionCounselingParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Nutrition Counseling
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIINutritionCounseling')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OANutritionCounselingParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OANutritionCounselingParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Nutrition Counseling
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIINutritionCounselingCharsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIINutritionCounselingChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIINutritionCounselingChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIINutritionCounselingChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIINutritionCounselingChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIINutritionCounselingChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIINutritionCounselingUnitsLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIINutritionCounselingUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIINutritionCounselingUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIINutritionCounselingUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIINutritionCounselingUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units, Expenditures and Program Income
                                </p>
                                <span *ngIf="router.url.includes('titleIIINutritionCounselingUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Nutrition Counseling-->
                    <!--Legal Assistance-->
                    <li *ngIf="!router.url.includes('Legal')">
                      <a id="titleIIILegalCharsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIILegalChars']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.OALegalParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OALegalParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Legal Assistance
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('Legal')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.OALegalParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OALegalParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Legal Assistance
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIILegalCharsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIILegalChars']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIILegalChars == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIILegalChars == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIILegalChars'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Consumer Characteristics
                                </p>
                                <span *ngIf="router.url.includes('titleIIILegalChars')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIILegalUnitsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIILegalUnits']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIILegalUnits == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIILegalUnits == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIILegalUnits'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIILegalUnits')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="OALegalCasesLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/OALegalCases']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.OALegalCases == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OALegalCases == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/OALegalCases'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Levels of Service and Types of Cases
                                </p>
                                <span *ngIf="router.url.includes('OALegalCases')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Legal Assistance-->
                    <!--Transportation-->
                    <li *ngIf="!router.url.includes('titleIIITransportation')">
                      <a id="titleIIITransportationLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIITransportation']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIITransportation == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIITransportation == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIITransportation'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Transportation
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIITransportation')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.titleIIITransportation == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIITransportation == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/titleIIITransportation'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Transportation
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIITransportationLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIITransportation']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIITransportation == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIITransportation == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIITransportation'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIITransportation')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Transportation-->
                    <!--Nutrition Education-->
                    <li *ngIf="!router.url.includes('titleIIINutritionEducation')">
                      <a id="titleIIINutritionEducationLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIINutritionEducation']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIINutritionEducation == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIINutritionEducation == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIINutritionEducation'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Nutrition Education
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIINutritionEducation')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.titleIIINutritionEducation == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIINutritionEducation == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/titleIIINutritionEducation'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Nutrition Education
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIINutritionEducationLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIINutritionEducation']"
                                [routerLinkActive]="['active']">
                                <i *ngIf="_claimService.tiiiStatuses.titleIIINutritionEducation == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIINutritionEducation == statusEnum.L2InProgressError"
                                  [ngClass]="(router.url.includes('/data-submissions/titleIIINutritionEducation'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                  aria-hidden="true">
                                  <span class="sr-only">Error:</span>
                                </i> Service Units and Expenditures
                                <span *ngIf="router.url.includes('titleIIINutritionEducation')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Nutrition Education-->
                    <!--Information and Assistance-->
                    <li *ngIf="!router.url.includes('titleIIIInfoAndAssistance')">
                      <a id="titleIIIInfoAndAssistanceLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIInfoAndAssistance']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIInfoAndAssistance == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIInfoAndAssistance == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIInfoAndAssistance'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Information and Assistance
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIInfoAndAssistance')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.titleIIIInfoAndAssistance == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIInfoAndAssistance == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/titleIIIInfoAndAssistance'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Information and Assistance
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIInfoAndAssistanceLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIInfoAndAssistance']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIInfoAndAssistance == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIInfoAndAssistance == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIInfoAndAssistance'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIInfoAndAssistance')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Information and Assistance-->
                    <!--Evidence-Based-->
                    <li *ngIf="!router.url.includes('healthPromoEvidence')">
                      <a id="healthPromoEvidenceLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/healthPromoEvidence']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.healthPromoEvidence == statusEnum.L1InProgressError || _claimService.tiiiStatuses.healthPromoEvidence == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/healthPromoEvidence'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Health Promotion: Evidence-Based
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('healthPromoEvidence')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.healthPromoEvidence == statusEnum.L1InProgressError || _claimService.tiiiStatuses.healthPromoEvidence == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/healthPromoEvidence'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Health Promotion: Evidence-Based
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="healthPromoEvidenceLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/healthPromoEvidence']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.healthPromoEvidence == statusEnum.L1InProgressError || _claimService.tiiiStatuses.healthPromoEvidence == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/healthPromoEvidence'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> People Served, Expenditures and Program Income
                                </p>
                                <span *ngIf="router.url.includes('healthPromoEvidence')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Evidence Based-->
                    <!--Non Evidence-Based-->
                    <li *ngIf="!router.url.includes('healthPromoNonEvidence')">
                      <a id="healthPromoNonEvidenceLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/healthPromoNonEvidence']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.healthPromoNonEvidence == statusEnum.L1InProgressError || _claimService.tiiiStatuses.healthPromoNonEvidence == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/healthPromoNonEvidence'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Health Promotion: Non Evidence-Based
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('healthPromoNonEvidence')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.healthPromoNonEvidence == statusEnum.L1InProgressError || _claimService.tiiiStatuses.healthPromoNonEvidence == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/healthPromoNonEvidence'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Health Promotion: Non Evidence-Based
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="healthPromoNonEvidenceLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/healthPromoNonEvidence']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.healthPromoNonEvidence == statusEnum.L1InProgressError || _claimService.tiiiStatuses.healthPromoNonEvidence == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/healthPromoNonEvidence'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> People Served, Expenditures and Program Income
                                </p>
                                <span *ngIf="router.url.includes('healthPromoNonEvidence')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Non Evidence Based-->
                    <!--Other Services-->
                    <li *ngIf="!router.url.includes('titleIIIOther')">
                      <a id="titleIIIOtherServicesLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIOtherServices']"
                        [routerLinkActive]="['active']">
                        <strong>
                          Other Services
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIOther')" class="leadNav subActive">
                      <strong>Other Services</strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIOtherServicesLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIOtherServices']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIOtherServices == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIOtherServices == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIOtherServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Services Provided
                                </p>
                                <span *ngIf="router.url.includes('titleIIIOtherServices')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIOtherExpendLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIOtherExpend']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIOtherExpend == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIOtherExpend == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIOtherExpend'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total People Served and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIOtherExpend')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Other Services-->
                  </ul>
                </li>
                <!--Caregivers of Older Adults-->
                <li id="tiii-coa-profile-top" (click)="displayCOA()" (keypress)="displayCOA()" class="leadNav"
                  role="button" tabindex="0" [ngClass]="{subActive: showCOA, leadNav: !isCOAHomeActive}">
                  <strong *ngIf="!isCOAHomeActive">
                    <i *ngIf="_claimService.tiiiStatuses.COAParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.COAParent == statusEnum.L2InProgressError"
                      [ngClass]="(router.url.includes('COA')) ? 'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                      aria-hidden="true">
                      <span class="sr-only">Error:</span>
                    </i> Caregivers of Older Adults
                  </strong>
                  <a id="activeCOAHomeLnk" class="active" *ngIf="isCOAHomeActive">
                    <p>
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.COAParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.COAParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Caregivers of Older Adults
                      </strong>
                    </p>
                    <span>
                      <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                    </span>
                  </a>
                  <ul [hidden]="!showCOA">
                    <!--show/hide onClick of li-->
                    <!--Consumer Summary-->
                    <li *ngIf="!router.url.includes('COAConsumer')">
                      <a id="COAConsumerTpsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/COAConsumerTps']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.ConsumerSummaryParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ConsumerSummaryParent == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/COAConsumerTps'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Consumer Summary
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('COAConsumer')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.ConsumerSummaryParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ConsumerSummaryParent == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/COAConsumerTps'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Consumer Summary
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="cOAConsumerTpsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/COAConsumerTps']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.COAConsumerTps == statusEnum.L1InProgressError || _claimService.tiiiStatuses.COAConsumerTps == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/COAConsumerTps'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total Persons Served
                                </p>
                                <span *ngIf="router.url.includes('COAConsumerTps')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIICOAConsumerSummaryLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOAConsumerSummary']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICOAConsumerSummary == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOAConsumerSummary == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICOAConsumerSummary'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Caregivers Receiving Registered Services
                                </p>
                                <span *ngIf="router.url.includes('titleIIICOAConsumerSummary')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <!-- end withSub -->
                      </ul>
                    </li>
                    <!--End Consumer Summary-->
                    <li *ngIf="!router.url.includes('titleIIICOACounseling')">
                      <a id="titleIIICOACounselingLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOACounseling']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIICOACounseling == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOACounseling == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIICOACounseling'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Counseling
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIICOACounseling')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.titleIIICOACounseling == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOACounseling == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/titleIIICOACounseling'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Counseling
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIICOACounselingLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOACounseling']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICOACounseling == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOACounseling == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICOACounseling'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total People Served, Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIICOACounseling')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li *ngIf="!router.url.includes('titleIIICOATraining')">
                      <a id="titleIIICOATrainingLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOATraining']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIICOATraining == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOATraining == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIICOATraining'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Training
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIICOATraining')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.titleIIICOATraining == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOATraining == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/titleIIICOATraining'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Training
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIICOATrainingLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOATraining']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICOATraining == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOATraining == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICOATraining'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total People Served, Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIICOATraining')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a id="titleIIICOARespiteLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOARespite']"
                        [routerLinkActive]="['active']">
                        <p>
                          <strong>
                            <i *ngIf="_claimService.tiiiStatuses.titleIIICOARespite == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOARespite == statusEnum.L2InProgressError"
                              [ngClass]="(router.url.includes('/data-submissions/titleIIICOARespite'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                              aria-hidden="true">
                              <span class="sr-only">Error:</span>
                            </i> Respite
                          </strong>
                        </p>
                        <span *ngIf="router.url.includes('/data-submissions/titleIIICOARespite')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <!--Supplemental Services-->
                    <li
                      *ngIf="!router.url.includes('titleIIICOASuppProvided') && !router.url.includes('titleIIICOASuppExpend')">
                      <a id="titleIIICOASuppProvidedLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOASuppProvided']"
                        [routerLinkActive]="['active']">
                        <strong>
                          Supplemental Services
                        </strong>
                      </a>
                    </li>
                    <li
                      *ngIf="router.url.includes('titleIIICOASuppProvided') || router.url.includes('titleIIICOASuppExpend')"
                      class="leadNav subActive">
                      <strong>Supplemental Services</strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIICOASuppProvidedLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOASuppProvided']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICOASuppProvided == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOASuppProvided == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICOASuppProvided'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Services Provided
                                </p>
                                <span *ngIf="router.url.includes('titleIIICOASuppProvided')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIICOASuppExpendLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOASuppExpend']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICOASuppExpend == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOASuppExpend == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICOASuppExpend'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total People Served and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIICOASuppExpend')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--Supplemental Services-->
                    <li *ngIf="!router.url.includes('titleIIICOACaseMgmt')">
                      <a id="titleIIICOACaseMgmtLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOACaseMgmt']"
                        [routerLinkActive]="['active']">
                        <p>
                          <strong>
                            <i *ngIf="_claimService.tiiiStatuses.titleIIICOACaseMgmt == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOACaseMgmt == statusEnum.L2InProgressError"
                              [ngClass]="(router.url.includes('/data-submissions/titleIIICOACaseMgmt'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                              aria-hidden="true">
                              <span class="sr-only">Error:</span>
                            </i> Case Management
                          </strong>
                        </p>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIICOACaseMgmt')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.titleIIICOACaseMgmt == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOACaseMgmt == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/titleIIICOACaseMgmt'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Case Management
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIICOACaseMgmtLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOACaseMgmt']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIICOACaseMgmt == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOACaseMgmt == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICOACaseMgmt'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total People Served, Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIICOACaseMgmt')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a id="titleIIICOASupportLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOASupport']"
                        [routerLinkActive]="['active']">
                        <p>
                          <strong>
                            <i *ngIf="_claimService.tiiiStatuses.titleIIICOASupport == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOASupport == statusEnum.L2InProgressError"
                              [ngClass]="(router.url.includes('/data-submissions/titleIIICOASupport'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                              aria-hidden="true">
                              <span class="sr-only">Error:</span>
                            </i> Support Groups
                          </strong>
                        </p>
                        <span *ngIf="router.url.includes('/data-submissions/titleIIICOASupport')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a id="titleIIICOAInfoAndAssistanceLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOAInfoAndAssistance']"
                        [routerLinkActive]="['active']">
                        <p>
                          <strong>
                            <i *ngIf="_claimService.tiiiStatuses.titleIIICOAInfoAndAssistance == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOAInfoAndAssistance == statusEnum.L2InProgressError"
                              [ngClass]="(router.url.includes('/data-submissions/titleIIICOAInfoAndAssistance'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                              aria-hidden="true">
                              <span class="sr-only">Error:</span>
                            </i> Information and Assistance
                          </strong>
                        </p>
                        <span *ngIf="router.url.includes('/data-submissions/titleIIICOAInfoAndAssistance')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a id="titleIIICOAInfoServicesLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOAInfoServices']"
                        [routerLinkActive]="['active']">
                        <p>
                          <strong>
                            <i *ngIf="_claimService.tiiiStatuses.titleIIICOAInfoServices == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIICOAInfoServices == statusEnum.L2InProgressError"
                              [ngClass]="(router.url.includes('/data-submissions/titleIIICOAInfoServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                              aria-hidden="true">
                              <span class="sr-only">Error:</span>
                            </i> Information Services
                          </strong>
                        </p>
                        <span *ngIf="router.url.includes('/data-submissions/titleIIICOAInfoServices')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <!--Older Relative Caregivers-->
                <li id="tiii-orc-profile-top" (click)="displayORC()" (keypress)="displayORC()" class="leadNav"
                  role="button" tabindex="0" [ngClass]="{subActive: showORC, leadNav: !isORCHomeActive}">
                  <strong *ngIf="!isORCHomeActive">
                    <i *ngIf="_claimService.tiiiStatuses.ORCParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ORCParent == statusEnum.L2InProgressError"
                      [ngClass]="showORC ?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                      aria-hidden="true">
                      <span class="sr-only">Error:</span>
                    </i> Older Relative Caregivers
                  </strong>
                  <a id="activeORCHomeLnk" class="active" *ngIf="isORCHomeActive">
                    <p>
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.ORCParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ORCParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Older Relative Caregivers
                      </strong>
                    </p>
                    <span>
                      <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                    </span>
                  </a>
                  <ul [hidden]="!showORC">
                    <!--show/hide onClick of li-->
                    <!--Consumer Summary-->
                    <li *ngIf="!router.url.includes('ORCConsumer')">
                      <a id="oRCConsumerTpsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/ORCConsumerTps']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.ORCConsumerSummaryParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ORCConsumerSummaryParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Consumer Summary
                        </strong>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('ORCConsumer')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.ORCConsumerSummaryParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ORCConsumerSummaryParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Consumer Summary
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIORCConsumerTpsLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/ORCConsumerTps']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.ORCConsumerTps == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ORCConsumerTps == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/ORCConsumerTps'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total Persons Served
                                </p>
                                <span *ngIf="router.url.includes('ORCConsumerTps')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIORCConsumerSummaryLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCConsumerSummary']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIORCConsumerSummary == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCConsumerSummary == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIORCConsumerSummary'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Caregivers Receiving Registered Services
                                </p>
                                <span *ngIf="router.url.includes('titleIIIORCConsumerSummary')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <!-- end withSub -->
                      </ul>
                    </li>
                    <!--End Consumer Summary-->
                    <li *ngIf="!router.url.includes('titleIIIORCCounseling')">
                      <a id="titleIIIORCCounselingLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCCounseling']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIORCCounseling == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCCounseling == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIORCCounseling'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Counseling
                        </p>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIORCCounseling')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.titleIIIORCCounseling == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCCounseling == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/titleIIIORCCounseling'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Counseling
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIORCCounselingLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCCounseling']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIORCCounseling == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCCounseling == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIORCCounseling'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total People Served, Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIORCCounseling')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!-- end counseling -->
                    <li *ngIf="!router.url.includes('titleIIIORCTraining')">
                      <a id="titleIIIORCTrainingLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCTraining']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIORCTraining == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCTraining == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIORCTraining'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Training
                        </p>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIORCTraining')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.titleIIIORCTraining == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCTraining == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/titleIIIORCTraining'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Training
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIORCTrainingLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCTraining']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIORCTraining == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCTraining == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIORCTraining'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total People Served, Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIORCTraining')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a id="titleIIIORCRespiteLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCRespite']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIORCRespite == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCRespite == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIORCRespite'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Respite
                        </p>
                        <span *ngIf="router.url.includes('/data-submissions/titleIIIORCRespite')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <!--Supplemental Services-->
                    <li
                      *ngIf="!router.url.includes('titleIIIORCSuppProvided') && !router.url.includes('titleIIIORCSuppExpend')">
                      <a id="titleIIIORCSuppProvidedLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCSuppProvided']"
                        [routerLinkActive]="['active']">
                        Supplemental Services
                      </a>
                    </li>
                    <li
                      *ngIf="router.url.includes('titleIIIORCSuppProvided') || router.url.includes('titleIIIORCSuppExpend')"
                      class="leadNav subActive">
                      <strong>Supplemental Services</strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIORCSuppProvidedLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCSuppProvided']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIORCSuppProvided == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCSuppProvided == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIORCSuppProvided'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Services Provided
                                </p>
                                <span *ngIf="router.url.includes('titleIIIORCSuppProvided')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIORCSuppExpendLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCSuppExpend']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIORCSuppExpend == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCSuppExpend == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIORCSuppExpend'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total People Served and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIORCSuppExpend')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--Supplemental Services-->
                    <li *ngIf="!router.url.includes('titleIIIORCCaseMgmt')">
                      <a id="titleIIIORCCaseMgmtLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCCaseMgmt']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIORCCaseMgmt == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCCaseMgmt == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIORCCaseMgmt'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Case Management
                        </p>
                      </a>
                    </li>
                    <li *ngIf="router.url.includes('titleIIIORCCaseMgmt')" class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.titleIIIORCCaseMgmt == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCCaseMgmt == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('/data-submissions/titleIIIORCCaseMgmt'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Case Management
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIORCCaseMgmtLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCCaseMgmt']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.titleIIIORCCaseMgmt == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCCaseMgmt == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIORCCaseMgmt'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Total People Served, Service Units and Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIORCCaseMgmt')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a id="titleIIIORCSupportLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCSupport']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIORCSupport == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCSupport == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIORCSupport'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Support Groups
                        </p>
                        <span *ngIf="router.url.includes('/data-submissions/titleIIIORCSupport')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a id="titleIIIORCInfoAndAssistanceLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCInfoAndAssistance']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIORCInfoAndAssistance == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCInfoAndAssistance == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIORCInfoAndAssistance'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Information and Assistance
                        </p>
                        <span *ngIf="router.url.includes('/data-submissions/titleIIIORCInfoAndAssistance')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a id="titleIIIORCInfoServicesLnk"
                        queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCInfoServices']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleIIIORCInfoServices == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIIORCInfoServices == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleIIIORCInfoServices'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Information Services
                        </p>
                        <span *ngIf="router.url.includes('/data-submissions/titleIIIORCInfoServices')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <!--Expenditures-->
                <li id="tiii-expenditures-profile-top" (click)="displayExpenditures()"
                  (keypress)="displayExpenditures()" role="button" tabindex="0"
                  [ngClass]="{leadNav: !isExpendituresHomeActive, subActive: showExpenditures}">
                  <strong *ngIf="!isExpendituresHomeActive">
                    <i *ngIf="_claimService.tiiiStatuses.ExpendituresSectionParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ExpendituresSectionParent == statusEnum.L2InProgressError"
                      [ngClass]="(showExpenditures)?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                      aria-hidden="true">
                      <span class="sr-only">Error:</span>
                    </i> Expenditures Overview
                  </strong>
                  <a id="expendituresHomeLnk" class="active" *ngIf="isExpendituresHomeActive">
                    <p>
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.ExpendituresSectionParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ExpendituresSectionParent == statusEnum.L2InProgressError"
                          [ngClass]="'fa fa-exclamation-circle'" aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Expenditures Overview
                      </strong>
                    </p>
                    <span>
                      <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                    </span>
                  </a>
                  <ul [hidden]="!showExpenditures">
                    <!--show/hide onClick of li-->
                    <!--Title 3 Expenditures-->
                    <!-- todo tidy up these conditions-->
                    <li *ngIf="!router.url.includes('OAExpenditures') && !router.url.includes('ORCExpenditures')">
                      <a id="titleIIIOAExpendituresLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIOAExpenditures']"
                        [routerLinkActive]="['active']">
                        <strong>
                          <i *ngIf="_claimService.tiiiStatuses.ServiceExpendituresParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ServiceExpendituresParent == statusEnum.L2InProgressError"
                            [ngClass]="'fa fa-exclamation-circle text-danger'" aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Title III Service Expenditures
                        </strong>
                      </a>
                    </li>
                    <!-- todo tidy up these conditions-->
                    <li
                      *ngIf="router.url.includes('titleIII') && router.url.includes('Expenditures') && !router.url.includes('titleIIIExpendituresHome')"
                      class="leadNav subActive">
                      <strong>
                        <i *ngIf="_claimService.tiiiStatuses.ServiceExpendituresParent == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ServiceExpendituresParent == statusEnum.L2InProgressError"
                          [ngClass]="(router.url.includes('titleIII') && router.url.includes('Expenditures'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                          aria-hidden="true">
                          <span class="sr-only">Error:</span>
                        </i> Title III Service Expenditures
                      </strong>
                      <ul>
                        <li class="withSub">
                          <ul>
                            <li>
                              <a id="titleIIIOAExpendituresLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIOAExpenditures']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.OAExpendituresChildren == statusEnum.L1InProgressError || _claimService.tiiiStatuses.OAExpendituresChildren == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIOAExpenditures'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Older Adults Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIOAExpenditures')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIICOAExpendituresLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIICOAExpenditures']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.COAExpendituresChildren == statusEnum.L1InProgressError || _claimService.tiiiStatuses.COAExpendituresChildren == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIICOAExpenditures'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Caregivers of Older Adults Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIICOAExpenditures')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a id="titleIIIORCExpendituresLnk"
                                queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIORCExpenditures']"
                                [routerLinkActive]="['active']">
                                <p>
                                  <i *ngIf="_claimService.tiiiStatuses.ORCExpendituresChildren == statusEnum.L1InProgressError || _claimService.tiiiStatuses.ORCExpendituresChildren == statusEnum.L2InProgressError"
                                    [ngClass]="(router.url.includes('/data-submissions/titleIIIORCExpenditures'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                                    aria-hidden="true">
                                    <span class="sr-only">Error:</span>
                                  </i> Older Relative Caregivers Expenditures
                                </p>
                                <span *ngIf="router.url.includes('titleIIIORCExpenditures')">
                                  <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!--End Title 3 Expenditures-->
                    <li>
                      <a id="titleVIIExpendituresLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleVIIExpenditures']"
                        [routerLinkActive]="['active']">
                        <p>
                          <i *ngIf="_claimService.tiiiStatuses.titleVIIExpenditures == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleVIIExpenditures == statusEnum.L2InProgressError"
                            [ngClass]="(router.url.includes('/data-submissions/titleVIIExpenditures'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                            aria-hidden="true">
                            <span class="sr-only">Error:</span>
                          </i> Title VII Expenditures
                        </p>
                        <span *ngIf="router.url.includes('titleVIIExpenditures')">
                          <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ng-container>
              <!-- .end SPR nav-->
              <!--NSIP-->
              <li *ngIf="iiiViewConstraints.isNsipViewable">
                <a id="titleIIInsipLnk" queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIInsip']"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>
                      <i *ngIf="_claimService.tiiiStatuses.titleIIInsip == statusEnum.L1InProgressError || _claimService.tiiiStatuses.titleIIInsip == statusEnum.L2InProgressError"
                        [ngClass]="(router.url.includes('/data-submissions/titleIIInsip'))?'fa fa-exclamation-circle':'fa fa-exclamation-circle text-danger'"
                        aria-hidden="true">
                        <span class="sr-only">Error:</span>
                      </i> NSIP Qualified Meals
                    </strong>
                  </p>
                  <span *ngIf="router.url.includes('/data-submissions/titleIIInsip')">
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
            </ul>
            <!--END TITLE 3 NAV-->
          </nav>
        </li>
        <li class="title mt-2">
          Variance Analysis
          <!-- tiii variance nav -->
          <ng-container *ngIf="currentTitle === 'III'">
            <ul>
              <!--Begin Title III Explanation of Variances logic for sub tabs-->
              <!--If SPR overall status AND NSIP overall status are less than statusEnum.L3NoExplanation, show disabled Variance Explanations left nav-->
              <li
                *ngIf="_claimService.tiiiStatuses.sprStatus < statusEnum.L3NoExplanation && _claimService.tiiiStatuses.nsipStatus < statusEnum.L3NoExplanation">
                <a id="titleIIIVariancesDisabledLnk" class="disabled">
                  <p>
                    <strong>Explanation of Variances</strong>
                  </p>
                </a>
              </li>
              <!--If SPR overall status OR NSIP overall status are greater than L2Validated, show standard Variance Explanations left nav-->
              <li id="tiii-sprve-profile-top"
                *ngIf="_claimService.tiiiStatuses.sprStatus > statusEnum.L2Validated || _claimService.tiiiStatuses.nsipStatus > statusEnum.L2Validated"
                (click)="displayVarianceExplanations()" (keypress)="displayVarianceExplanations()"
                [ngClass]="{leadNav: !isVarianceHomeActive, subActive: showVarianceExplanations}">
                <p *ngIf="!isVarianceHomeActive">
                  <strong>Explanation of Variances</strong>
                </p>
                <a id="activeVarianceLnk" class="active" *ngIf="isVarianceHomeActive">
                  <p>
                    <strong>Explanation of Variances</strong>
                  </p>
                  <span>
                    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                  </span>
                </a>
                <ul [hidden]="!showVarianceExplanations">
                  <!--If SPR overall status is less than statusEnum.L3NoExplanation, show the disabled left nav for SPR items-->
                  <li
                    *ngIf="_claimService.tiiiStatuses.sprStatus < statusEnum.L3NoExplanation || !_claimService.tiiiStatuses.oaIsRequired">
                    <a id="titleIIIOADisabledLnk" class="disabled">
                      <p> Older Adults</p>
                    </a>
                  </li>
                  <!--If SPR overall status is greater than statusEnum.L2Validated, show the standard left nav for SPR items-->
                  <li
                    *ngIf="_claimService.tiiiStatuses.sprStatus > statusEnum.L2Validated && _claimService.tiiiStatuses.oaIsRequired">
                    <a id="titleIIIVarianceExplanationsOALnk"
                      queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIVarianceExplanationsOA']"
                      [routerLinkActive]="['active']">
                      <p>Older Adults</p>
                      <span *ngIf="router.url.includes('titleIIIVarianceExplanationsOA')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li
                    *ngIf="_claimService.tiiiStatuses.sprStatus < statusEnum.L3NoExplanation || !_claimService.tiiiStatuses.coaIsRequired">
                    <a id="titleIIICOADisabledLnk" class="disabled">
                      <p>Caregivers of Older Adults</p>
                    </a>
                  </li>

                  <li
                    *ngIf="_claimService.tiiiStatuses.sprStatus > statusEnum.L2Validated && _claimService.tiiiStatuses.coaIsRequired">
                    <a id="titleIIIVarianceExplanationsCGLnk"
                      queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIVarianceExplanationsCG']"
                      [routerLinkActive]="['active']">
                      <p>Caregivers of Older Adults</p>
                      <span *ngIf="router.url.includes('titleIIIVarianceExplanationsCG')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li
                    *ngIf="_claimService.tiiiStatuses.sprStatus < statusEnum.L3NoExplanation || !_claimService.tiiiStatuses.orcIsRequired">
                    <a id="titleIIIORCDisabledLnk" class="disabled">
                      <p> Older Relative Caregivers</p>
                    </a>
                  </li>
                  <li
                    *ngIf="_claimService.tiiiStatuses.sprStatus > statusEnum.L2Validated && _claimService.tiiiStatuses.orcIsRequired">
                    <a id="titleIIIVarianceExplanationsORLnk"
                      queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIVarianceExplanationsOR']"
                      [routerLinkActive]="['active']">
                      <p>Older Relative Caregivers</p>
                      <span *ngIf="router.url.includes('titleIIIVarianceExplanationsOR')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <!--If NSIP overall status is less than statusEnum.L3NoExplanation, show the disabled left nav for NSIP-->
                  <li
                    *ngIf="_claimService.tiiiStatuses.nsipStatus < statusEnum.L3NoExplanation || !_claimService.tiiiStatuses.nsipIsRequired">
                    <a id="titleIIINSIPDisabledLnk" class="disabled">
                      <p>
                        <strong>
                          NSIP Qualified Meals
                        </strong>
                      </p>
                    </a>
                  </li>
                  <!--If NSIP overall status is greater than statusEnum.L2Validated, show the left nav for NSIP-->
                  <li
                    *ngIf="_claimService.tiiiStatuses.nsipStatus > statusEnum.L2Validated && _claimService.tiiiStatuses.nsipIsRequired">
                    <a id="titleIIIVarianceExplanationsNSIPLnk"
                      queryParamsHandling="preserve" [routerLink]="['/data-submissions/titleIIIVarianceExplanationsNSIP']"
                      [routerLinkActive]="['active']">
                      <p>
                        <strong>
                          NSIP Qualified Meals
                        </strong>
                      </p>
                      <span *ngIf="router.url.includes('titleIIIVarianceExplanationsNSIP')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
              <!--End Title III Variance Explanation logic for sub tabs-->
            </ul>
          </ng-container>

          <!-- tvi variance nav -->
          <acl-subnav-tvi-variance [overviewState]="tviOverviewState" [overviewDtos]="overviewDtos">
          </acl-subnav-tvi-variance>

          <!-- tvii variance nav -->
          <ng-container *ngIf="currentTitle === 'VII'">
            <ul>
              <!--Begin Title VII Explanation of Variances logic for sub tabs-->
              <li *ngIf="disableT7VENav()">
                <a id="titleVIIVariancedDisabledLnk" class="disabled">
                  <strong>Explanation of Variances</strong>
                </a>
              </li>
              <li>
                <a id="firstAvilableT7VESectionLnk"
                  *ngIf="!disableT7VENav() && !router.url.includes('/state-submissions/titleVIICaseAndComplaintsExplanations') && !router.url.includes('/state-submissions/titleVIIStaffAndVolunteersExplanations') && !router.url.includes('/state-submissions/titleVIIFundsExpendedExplanations') && !router.url.includes('/state-submissions/titleVIIFacilityExplanations') && !router.url.includes('/state-submissions/titleVIIProgramActivitiesExplanations') && !router.url.includes('/state-submissions/titleVIIOrganizationalStructureExplanations')"
                  [routerLink]="['/state-submissions/' + _claimService.tviiStatuses.firstAvilableT7VESection()]"
                  [routerLinkActive]="['active']">
                  <p>
                    <strong>Explanation of Variances</strong>
                  </p>
                </a>
              </li>
              <li
                *ngIf="router.url.includes('/state-submissions/titleVIICaseAndComplaintsExplanations') || router.url.includes('/state-submissions/titleVIIStaffAndVolunteersExplanations') || router.url.includes('/state-submissions/titleVIIFundsExpendedExplanations') || router.url.includes('/state-submissions/titleVIIFacilityExplanations') || router.url.includes('/state-submissions/titleVIIProgramActivitiesExplanations') || router.url.includes('/state-submissions/titleVIIOrganizationalStructureExplanations')"
                class="leadNav subActive">
                <strong>Explanation of Variances</strong>
                <ul>
                  <li *ngIf="_claimService.tviiStatuses.caseAndComplaintSectionStatus > statusEnum.L2Validated"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIICaseAndComplaintsExplanations'))?'active':''">
                    <a id="titleVIICaseAndComplaintsExplanationsLnk"
                      [routerLink]="['/state-submissions/titleVIICaseAndComplaintsExplanations']"
                      [routerLinkActive]="['active']">
                      <p>Cases and Complaints</p>
                      <span *ngIf="router.url.includes('/state-submissions/titleVIICaseAndComplaintsExplanations')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.caseAndComplaintSectionStatus < statusEnum.L3NoExplanation">
                    <a id="caseAndComplaintSectionStatusDisabledLnk" class="disabled">
                      <p>Cases and Complaints</p>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.orgStructureSectionStatus > statusEnum.L2Validated"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIIOrganizationalStructureExplanations'))?'active':''">
                    <a id="titleVIIOrganizationalStructureExplanationsLnk"
                      [routerLink]="['/state-submissions/titleVIIOrganizationalStructureExplanations']"
                      [routerLinkActive]="['active']">
                      <p>Organizational Structure</p>
                      <span
                        *ngIf="router.url.includes('/state-submissions/titleVIIOrganizationalStructureExplanations')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.orgStructureSectionStatus < statusEnum.L3NoExplanation">
                    <a id="titleVIIOrgStructureDisabledLnk" class="disabled">
                      <p>Organizational Structure</p>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.staffAndVolunteerSectionStatus > statusEnum.L2Validated"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIIStaffAndVolunteersExplanations'))?'active':''">
                    <a id="titleVIIStaffAndVolunteersExplanationsLnk"
                      [routerLink]="['/state-submissions/titleVIIStaffAndVolunteersExplanations']"
                      [routerLinkActive]="['active']">
                      <p>Staff and Volunteers</p>
                      <span *ngIf="router.url.includes('/state-submissions/titleVIIStaffAndVolunteersExplanations')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.staffAndVolunteerSectionStatus < statusEnum.L3NoExplanation">
                    <a id="StaffAndVolunteerDisabledLnk" class="disabled">
                      <p>Staff and Volunteers</p>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.fundsExpendedSectionStatus > statusEnum.L2Validated"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIIFundsExpendedExplanations'))?'active':''">
                    <a id="titleVIIFundsExpendedExplanationsLnk"
                      [routerLink]="['/state-submissions/titleVIIFundsExpendedExplanations']"
                      [routerLinkActive]="['active']">
                      <p>Funds Expended</p>
                      <span *ngIf="router.url.includes('/state-submissions/titleVIIFundsExpendedExplanations')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.fundsExpendedSectionStatus < statusEnum.L3NoExplanation">
                    <a id="titleVIIFundsExpendedDisabledLnk" class="disabled">
                      <p>Funds Expended</p>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.facilityParentSectionStatus > statusEnum.L2Validated"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIIFacilityExplanations'))?'active':''">
                    <a id="titleVIIFacilityExplanationsLnk"
                      [routerLink]="['/state-submissions/titleVIIFacilityExplanations']"
                      [routerLinkActive]="['active']">
                      <p>Facility</p>
                      <span *ngIf="router.url.includes('/state-submissions/titleVIIFacilityExplanations')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.facilityParentSectionStatus < statusEnum.L3NoExplanation">
                    <a id="titleVIIFacilityParentDisabledLnk" class="disabled">
                      <p>Facility</p>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.programActivitiesSectionStatus > statusEnum.L2Validated"
                    [ngClass]="(router.url.includes('/state-submissions/titleVIIProgramActivitiesExplanations'))?'active':''">
                    <a id="titleVIIProgramActivitiesExplanationsLnk"
                      [routerLink]="['/state-submissions/titleVIIProgramActivitiesExplanations']"
                      [routerLinkActive]="['active']">
                      <p>Program Activities</p>
                      <span *ngIf="router.url.includes('/state-submissions/titleVIIProgramActivitiesExplanations')">
                        <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li *ngIf="_claimService.tviiStatuses.programActivitiesSectionStatus < statusEnum.L3NoExplanation">
                    <a id="titleVIIProgramActivitiesDisabledLnk" class="disabled">
                      <p>Program Activities</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!--End Title VII Variance Explanation logic for sub tabs-->

            </ul>
          </ng-container>

        </li>
      </ul>
    </nav>
    <!-- .end side navigation -->
  </div>
  <!-- .end panel -->
</div>
