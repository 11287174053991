import { Section } from './section';
import { UploadTypeEnum } from '../app.constants';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { CaseComplaintErrorDetail, BasicErrorDetail } from './Upload/uploadError';


export class BatchSection extends Section {
    fileName: string;
    isUploadComplete: boolean;
    uploadFailedMessage: string;
    comment: string;
    includeInDataset: string;
    uploadType: UploadTypeEnum;
    sectionsIncluded: Array<SectionDetail>;
    uploadError: string;
    caseComplaintErrors: Array<CaseComplaintErrorDetail>;
    errors: Array<BasicErrorDetail>;
    link?: string;
}

export class SectionDetail {
    id: string;
    name: string;
    friendlyName: string;
    resource: string;
    created: Date;
    includeInDataset: boolean;
}

export function getUploadTypeFriendlyName(uploadType: UploadTypeEnum, sanitizer: DomSanitizer): SafeHtml {
    switch (uploadType) {
        case UploadTypeEnum.NewData:
            return sanitizer.bypassSecurityTrustHtml('New data');
        case UploadTypeEnum.Merge:
            return sanitizer.bypassSecurityTrustHtml('Merge');
        case UploadTypeEnum.Overwrite:
            return sanitizer.bypassSecurityTrustHtml('Overwrite');
        case UploadTypeEnum.Pending:
            return sanitizer.bypassSecurityTrustHtml('<span class="text-warning">Processing</span>');
        case UploadTypeEnum.Success:
            return sanitizer.bypassSecurityTrustHtml('<span class="text-success">Success</span>');
        case UploadTypeEnum.Failed:
            return sanitizer.bypassSecurityTrustHtml('<span class="text-danger">Failed</span>');
        default:
            return '';
    }
}
