<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- merge section warning -->

      <!-- old functionality that allows merging of sections, not deleting in case we want to re-enable it -->
      <!-- <div id="mergeConfirmationModal" class="modal fade">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title">Merge data in multiple uploads?</h2>
                            </div>
                            <div class="modal-body">
                                <p>You have selected overlapping sections from different uploaded files. If you chose to continue, data included in the selected files will be added and included in the state dataset.</p>
                                <p>Are you sure you want to continue to merge data included in these uploads?</p>
                            </div>
                            <div class="modal-footer">
                                <button (click)="mergeSections()" type="button" class="btn btn-primary">Yes, merge data</button>
                                <button (click)="dismissWarning()" type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>  -->

      <div id="modalMore" class="modal fade">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title">{{_modalMoreTitle}}</h2>
            </div>
            <div class="modal-body">
              <p *ngIf="_modalMoreBodyText">{{_modalMoreBodyText}}</p>
              <ul *ngIf="_modalMoreBodyList">
                <li *ngFor="let item of _modalMoreBodyList">{{item}}</li>
              </ul>
            </div>
            <div class="modal-footer">
              <button (click)="modalDismissMore()" type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <!-- .end merge section warning -->

      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <loading #loadingSpinner></loading>
        <h2>Manage Uploads</h2>
        <!-- alerts -->
        <div *ngIf="_showUploadDisabledMessage" class="alert alert-warning" role="alert">
          <div class="table-cell pt-10px">
            <p>Currently processing a previously uploaded file. Upload functionality will be available momentarily.</p>
          </div>
        </div>
        <!-- .end alerts -->
        <!-- uploads -->
        <div *ngIf="_batchUploads?.length > 0">
          Upload new files or view uploaded files
          <button [routerLink]="['../uploadfile']" queryParamsHandling="preserve" [disabled]="_isUploadDisabled || _claimService.isViewOnly || _isIncompleteUpload"
            class="btn btn-primary pull-right" id="uploadbtn">Upload a new file</button>
          <div class="col-md-12 table-responsive">
            <table class="table">
              <caption>Upload Log</caption>
              <thead>
                <tr class="background-gray-lighter">
                  <th width="25%" scope="col">File</th>
                  <th width="15%" scope="col">Type</th>
                  <th width="30%" scope="col">Sections Included</th>
                  <th width="30%" scope="col">Comments</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let batch of _batchUploads">
                  <td>
                    <ng-container *ngIf="batch.isUploadComplete">
                      <a
                        href="{{batch.link}}">{{batch.fileName}}</a>
                      <small class="block" *ngIf="!batch.created.startsWith('0001')">{{batch.created | date :
                                          'shortDate'}} at {{batch.created | date : 'shortTime'}} by</small>
                      <small class="block">{{batch.createdBy}}</small>
                    </ng-container>
                    <ng-container *ngIf="!batch.isUploadComplete">
                      <span class="text-warning">File Unavailable</span> <small class="block">{{batch.uploadFailedMessage || 'File upload failed.'}}</small>
                    </ng-container>
                  </td>
                  <td [innerHTML]="getUploadTypeFriendlyNameWrapper(batch.uploadType, _sanitizer)"></td>

                  <!-- old functionality that allows merging of sections, not deleting in case we want to re-enable it -->
                  <!-- <td>
                                                        <label style="display:block;" *ngFor="let section of distinctSections(batch.sectionsIncluded)" for="{{'include_' + batch.id + '_' + section.name}}">
                                                            <input [checked]="section.includeInDataset" [disabled]="section.name == 'CaseAndComplaintSection'" (change)="section.includeInDataset = !section.includeInDataset; addSectionToMerge(section.includeInDataset, section, batch)" id="{{'include_' + batch.id + '_' + section.name}}" name="{{'include_' + batch.id + '_' + section.name}}" type="checkbox" value="include" aria-label="selectName">
                                                            {{section.friendlyName}}
                                                        </label>
                                                    </td> -->

                  <td>
                    <div *ngIf="batch.sectionsIncluded && (!!(!batch.caseComplaintErrors) || batch.caseComplaintErrors?.length <= 0) && (!!(!batch.errors) || batch.errors?.length <= 0)">
                      <ul class="raw-list">
                        <li *ngFor="let section of batch.sectionsIncluded.slice(0, 2);">{{section.friendlyName}}</li>
                      </ul>
                      <a *ngIf="batch.sectionsIncluded.length > 2" tabindex="0" (click)="modalShowMoreSections(batch)">...more</a>
                    </div>
                    <div *ngIf="batch.caseComplaintErrors?.length > 0">
                      Cases and Complaints data was not uploaded due to validation errors
                      <button class="btn-sm btn-danger block mt-1 text-center" (click)=viewCaseComplaintErrors(batch)>View
                        Errors</button>
                    </div>
                    <div *ngIf="batch.errors?.length > 0">
                      Data was not uploaded due to validation errors
                      <button class="btn-sm btn-danger block mt-1 text-center" (click)=viewErrors(batch)>View Errors</button>
                    </div>
                  </td>
                  <td>{{batch.comment | truncate : 100 }}
                    <a *ngIf="batch.comment.length > 100" tabindex="0" (click)="modalShowMoreComment(batch)">more</a>
                  </td>
                  <!-- <td><button [disabled]="_claimService.isViewOnly" (click)="deleteBatchUpload(batch.id)" class="btn btn-danger" id="uniqueID0">Delete</button></td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- .end uploads -->

        <!-- no-uploads -->
        <div *ngIf="!_batchUploads || _batchUploads.length <= 0" class="col-md-12">
          <span class="mb-1 block">No files have been uploaded yet.</span>
          <span class="block">To upload a new file or download templates, go to the upload new file page.</span>
          <button id="uploadbtn" [routerLink]="['../uploadfile']" queryParamsHandling="preserve" [disabled]="_claimService.isViewOnly || _isUploadDisabled" class="btn btn-primary block mt-1">Upload
            a new file</button>
        </div>
        <!-- .end no-uploads -->

        <div class="m-1">
          <div class="row col-md-12">

            <a *ngIf="_claimService.currentTitle === _titleEnum[_titleEnum.VI]" class="btn btn-link" [routerLink]="['/titlevi/titleVIPPROverview']">
              Return to Overview
            </a>
          </div>
        </div>

        <!-- old functionality that allows merging of sections, not deleting in case we want to re-enable it -->
        <!-- <div><button [disabled]="_sectionsToMerge.length <= 0 && _batchSectionsToUpdate.length <= 0" (click)="merge()" class="btn btn-primary" id="mergeData">Merge data</button></div> -->
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
