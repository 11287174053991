import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';


import { BatchSection, SectionDetail, getUploadTypeFriendlyName } from '../batchSection';
import { EmptyGuid, AclTitleEnum } from '../../app.constants';
import { SectionLookup } from '../sectionLookup';
import { ClaimService } from '../claim.service';
import { UploadService } from './upload.service';
import { QuestionStatusEnum } from '../dataElement';


import * as _ from 'lodash-es';
import { switchMap } from 'rxjs/operators';
import { StatusLoaderService } from '../status-loader.service';
declare var jQuery: any;

@Component({
    templateUrl: 'manageUploads.component.html',
    styles: [
        `.modal-header > h1 { text-transform: none; color: black; font-size: 20px;
            font-weight: bold; border-bottom-width: 0px; padding-bottom: 0px; }`,
        '.modal-body > .table { margin-bottom: 0px; }',
        '.modal-body > .table > tbody > tr > td { border-top: 0px; width: 70%; }',
        '.modal-body > .table > tbody > tr > th { border-top: 0px; width: 30% }'
    ]
})

export class ManageUploadsComponent implements OnInit, OnDestroy {

    _batchUploads: Array<BatchSection>;
    _sectionsToMerge: Array<SectionDetail>;
    _batchSectionsToUpdate: Array<BatchSection>;
    _modalMoreTitle: string;
    _modalMoreBodyText: string;
    _modalMoreBodyList: Array<string>;
    _isUploadDisabled: boolean = true;
    _showUploadDisabledMessage = false;
    _activeSubs = [];
    _statusEnum = QuestionStatusEnum;
    _titleEnum = AclTitleEnum;
    _isIncompleteUpload: boolean = true;
    _tiiiCannotUpload: boolean = true;
    _tviCannotUpload: boolean = true;
    _tviiCannotUpload: boolean = true;
    tviStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    fileLink: string = '';
    // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;

    constructor(public zone: NgZone,
        public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _service: UploadService,
        public _claimService: ClaimService,
        public _sanitizer: DomSanitizer,
        private _statusLoaderService: StatusLoaderService
    ) { }
    ngOnInit(): void {
        this._titleService.setTitle('Manage Uploads - State Submissions - ACL OAAPS');
        this._sectionsToMerge = new Array<SectionDetail>();
        this._batchSectionsToUpdate = new Array<BatchSection>();
        this._statusLoaderService.statusInitialized$().subscribe(() => {
            this.getBatchUploads();
            this.canUpload();
        }, err => { console.log(err); });
    }

    ngOnDestroy(): void {
        this._activeSubs.forEach(x => x.unsubscribe());
    }

    getBatchUploads(checkForUploadInProg: boolean = true): void {
        this.route.queryParams.pipe(
            switchMap(p => this._service
                .getbatchbyffystatetitle(this._claimService.getFFyStateDto(p['psa'], p['rollup']), this._claimService.currentTitle))
        ).subscribe(data => {
            data.forEach(file => {
                file['link'] = "/api/upload/blob?title="+this._claimService.currentTitle+"&filename="+file.fileName+"&batchId="+file.id;
            })
            this._batchUploads = data
                .filter(d => d.id !== EmptyGuid)
                .sort((left, right) =>
                    Date.parse(left.created) > Date.parse(right.created) ? -1 : 1);
            if (checkForUploadInProg) {
                this.checkForUploadInProgress();
            }
        });
    }

    checkForUploadInProgress(): void {
        if (this._batchUploads && this._batchUploads.length > 0) {
            // Already sorted by created
            const latestUpload = this._batchUploads[0];
            const poller = this._service.pollForCompletedBatchSection(latestUpload.id, this._claimService.title,
                (isDisabled) => {
                    if (!isDisabled) {
                        poller.unsubscribe();
                        const removeIndex = this._activeSubs.indexOf(poller);
                        if (removeIndex !== -1) {
                            this._activeSubs.splice(removeIndex, 1);
                        }
                        this.getBatchUploads(false);
                    }
                    this._isIncompleteUpload = this._showUploadDisabledMessage = isDisabled;
                });
            this._activeSubs.push(poller);
        }
    }

    distinctSections(sectionDetails: Array<SectionDetail>): Array<SectionDetail> {
        if (!sectionDetails) {
            return new Array<SectionDetail>();
        }
        const uniqueSections = _.uniqBy(sectionDetails, 'name');
        return uniqueSections;
    }

    distinctSectionNames(sectionDetails: Array<SectionDetail>): string {
        return this.distinctSections(sectionDetails).map(orig => orig.friendlyName).join(', ');
    }

    addSectionToMerge(isChecked: boolean, sectionDetail: SectionDetail, batchSection: BatchSection) {
        let i;

        // Add batch section to update if it's not already marked for update
        if ((i = this._batchSectionsToUpdate.indexOf(batchSection)) === -1) {
            this._batchSectionsToUpdate.push(batchSection);
        }
        // If checked add section to merge list
        if (isChecked) {
            this._sectionsToMerge.push(sectionDetail);
        } else if ((i = this._sectionsToMerge.indexOf(sectionDetail)) !== -1) {
            // Otherwise remove section if it is in the merge list
            this._sectionsToMerge.splice(i, 1);
        }
    }

    // Show modal
    // merge(): void {
    //     if (this.hasDuplicates(this._sectionsToMerge.map(x => x.name))) {
    //         jQuery('#mergeConfirmationModal').modal('show');
    //     } else {
    //         this.mergeSections();
    //     }
    // }

    // Show more modal
    modalShowMoreSections(section: BatchSection) {
        this._modalMoreTitle = 'Sections Included in Upload';
        this._modalMoreBodyText = null;
        this._modalMoreBodyList = section.sectionsIncluded.map(item => item.friendlyName);
        jQuery('#modalMore').modal('show');
    }

    modalShowMoreComment(section: BatchSection) {
        this._modalMoreTitle = 'Comments';
        this._modalMoreBodyList = null;
        this._modalMoreBodyText = section.comment;
        jQuery('#modalMore').modal('show');
    }
    // Dismiss more modal
    modalDismissMore() {
        jQuery('#modalMore').modal('hide');
    }

    public hasDuplicates(input: Array<string>): boolean {
        const uniq = input
            .map((item) => {
                return { count: 1, item: item };
            })
            .reduce((accumulator, currentVal) => {
                accumulator[currentVal.item] = (accumulator[currentVal.item] || 0) + currentVal.count;
                return accumulator;
            }, {});

        const duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);

        return duplicates.length > 0;
    }

    dismissWarning() {
        jQuery('#mergeConfirmationModal').modal('hide');
    }

    // Perform merge
    // mergeSections() {
    //     jQuery('#mergeConfirmationModal').modal('hide');
    //     this._service.mergeSections(this._sectionsToMerge,
    //         this._batchSectionsToUpdate,
    //         this._claimService.currentTitle,
    //         this._claimService.ffyStateDto)
    //         .subscribe(data => { alert('The merge was successful'); this.resetSelections(); },
    //             error => this.handleError(error));
    // }

    public resetSelections(): void {
        this._sectionsToMerge = new Array<SectionDetail>();
        this._batchSectionsToUpdate = new Array<BatchSection>();
    }

    handleError(error): void {
        // Unknown error
        if (error.code === 1999) {
            console.log(error);
            alert('We\'re sorry an error occurred during merging');
        } else {
            // Other errors
            console.log(error);
            alert('We\'re sorry an error occurred during merging');
        }
    }

    sectionDisplay(sectionKey: string): string {
        if (sectionKey) {
            return SectionLookup[sectionKey] || sectionKey;
        } else {
            return null;
        }
    }

    viewCaseComplaintErrors(batch: BatchSection) {
        // Get filetype
        const parts = batch.fileName.split('.');
        this._service.uploadError = {
            code: 1006,
            description: batch.uploadError,
            formatErrors: batch.caseComplaintErrors,
            fileType: parts[parts.length - 1]
        };
        this.router.navigate(['/state-submissions/errors']);
    }

    viewErrors(batch: BatchSection) {
        // Get filetype
        const parts = batch.fileName.split('.');
        this._service.uploadError = {
            code: 1001,
            description: batch.uploadError,
            formatErrors: batch.errors,
            fileType: parts[parts.length - 1]
        };
        this.router.navigate(['/state-submissions/errors'], {
            queryParams: {
                tvistatus: this.tviStatus
            }
        });
        // this.router.navigate(['/state-submissions/errors']);
    }

    canUpload() {
        // const tviStatus = QuestionStatusEnum.NotStarted;
        this.route.queryParams.subscribe(qsMap => {
            if (qsMap['tvistatus']) {
                this.tviStatus = qsMap['tvistatus'];
                this._tviCannotUpload = this.tviStatus >= this._statusEnum.SubmittedNoExplanation;
            } else if (this._claimService.currentTitle !== AclTitleEnum[AclTitleEnum.VI]) {
                this._tviCannotUpload = false;
            }
        });

        this._tviiCannotUpload = this._claimService.tviiStatuses.tVIIOverallStatus >= this._statusEnum.SubmittedNoExplanation;
        this._tiiiCannotUpload = this._claimService.tiiiStatuses.sprStatus >= this._statusEnum.SubmittedNoExplanation &&
            this._claimService.tiiiStatuses.nsipStatus >= this._statusEnum.SubmittedNoExplanation;

        this._isUploadDisabled = this._tviCannotUpload || this._tviiCannotUpload || this._tiiiCannotUpload;
    }

    // Function wrappers
    public get getUploadTypeFriendlyNameWrapper(): Function {
        return getUploadTypeFriendlyName;
    }

    getFileLink(batch) {
        this.fileLink = "/api/upload/blob?title="+this._claimService.currentTitle+"&filename="+batch.fileName+"&batchId="+batch.id;
    }
}
